// 27

export const data = {
  1: { title: '21.010.03 Газго', id: 1, children: [ 8, 9, 10, ], },
  8: { title: '20.002.00 Казалмиткс', id: 8, parentId: 1, },
  9: { title: '20.002.00 Казалмиткс', id: 9, parentId: 1, },
  10: { title: '20.002.00 Казалмиткс', id: 10, parentId: 1, },
}

export default [
]
