import FileTree from "../../data/file-tree";
export default async function (id) {
  let files = [];
  try {
    files = (await import("../../data/single-tab/" + id)).default;
  } catch (error) {
    console.warn("apiPlug post error on 'single-tab' query, id: " + id);
    console.warn(error);
  }

  return [...FileTree.filter((item) => item.parentId == id), ...files];
}
